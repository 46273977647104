Array.prototype.remove = function (from, to) {
	let left;
	const rest = this.slice(((to || from) + 1) || this.length);
	this.length = (left = from < 0) != null ? left : this.length + {from};
	return this.push.apply(this, rest);
};

Array.prototype.removeValue = function (...args) {
	const output = [];
	for (let arg of Array.from(args)) {
		const index = this.indexOf(arg);
		if (index >= 0) {
			output.push(this.splice(index, 1)[0]);
		}
	}
	return output;
};

if (Array.prototype.filter == null) {
	Array.prototype.filter = function (callback) {
		return (() => {
			const result = [];
			for (let element of Array.from(this)) {
				if (callback(element)) {
					result.push(element);
				}
			}
			return result;
		})();
	};
}

if (Array.prototype.last == null) {
	Array.prototype.last = function () {
		return this[this.length - 1];
	};
}

if (Array.prototype.indexOf == null) {
	Array.prototype.indexOf = function (what, i) {
		i = i || 0;
		const L = this.length;
		while (i < L) {
			if (this[i] === what) {
				return i;
			}
			++i;
		}
		return -1;
	};
}

String.prototype.titleize = function () {
	return this.toLowerCase().replace(/_/g, ' ').replace(/(?:^|\s| )(\S)/g, c => c.toUpperCase());
};

String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.camelize = function () {
	return this.titleize().replace(/ /g, '');
};

if (String.prototype.endsWith == null) {
	String.prototype.endsWith = function (searchString, position) {
		const subjectString = this.toString();
		if ((typeof position !== 'number') || !isFinite(position) || (Math.floor(position) !== position) || (position > subjectString.length)) {
			position = subjectString.length;
		}
		position -= searchString.length;
		const lastIndex = subjectString.lastIndexOf(searchString, position);
		return (lastIndex !== -1) && (lastIndex === position);
	};
}

if (String.prototype.startsWith == null) {
	String.prototype.startsWith = function (searchString, position) {
		position = position || 0;
		return this.substr(position, searchString.length) === searchString;
	};
}

if (Element.prototype.insertAfter == null) {
	Element.prototype.insertAfter = function (newNode) {
		return this.parentNode.insertBefore(newNode, this.nextSibling);
	};
}

Number.prototype.noExponents = function () {
	const data = String(this).split(/[eE]/);
	if (data.length === 1) {
		return data[0];
	}
	let z = '';
	const sign = this < 0 ? '-' : '';
	const str = data[0].replace('.', '');
	let mag = Number(data[1]) + 1;
	if (mag < 0) {
		z = sign + '0.';
		while (mag++) {
			z += '0';
		}
		return z + str.replace(/^\-/, '');
	}
	mag -= str.length;
	while (mag--) {
		z += '0';
	}
	return str + z;
};

$.fn.replaceWithCallback = function (replace, callback) {
	const response = $.fn.replaceWith.call(this, replace);
	if (typeof callback === 'function') {
		callback.call(response);
	}
	return response;
};