import DataTable from 'datatables.net-bs5';
import locale from '../src/table_language.json';
import {htmlLang, skipPageCache} from './common_methods';

export class Table {
	static defaultOptions = {
		'aaSorting': [],
		'language': locale[htmlLang],
		'dom': `<"row"
      <"col-12 col-md-6 mb-2" l><"col-12 col-md-6 mb-2" f>
      <"col-12  mb-2" tr>
      <"col-12 col-md-6 mb-2" i><"col-12 col-md-6 mb-2" p>
    >`
	};

	constructor(table, options = {}) {
		this.table = table;
		this.options = Object.assign(this.constructor.defaultOptions, options);
		this.init();
	}

	init() {
		skipPageCache();
		this.api = new DataTable(this.table, this.options);
	}

	reload() {
		this.api.ajax.reload();
	}
}