// import Bar from "./foo" находит файл foo.js в той же директории, что и текущий файл
// import Bar from "../src/foo" находит файл в соседней директории с именем src
// noinspection JSUnresolvedFunction

// chunks
import '../src/core_extentions';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import {Toast} from 'bootstrap';
import {Table} from '../src/datatable';

Rails.start();
Turbolinks.start();
require.context('../images', true);

document.addEventListener('turbolinks:load', function () {
	const tables = document.querySelectorAll('table[data-wrapper="datatable"]');
	const toasts = document.querySelectorAll('.toast');

	tables.forEach(table => new Table(table));
	toasts.forEach(toastEl => new Toast(toastEl).show());
});
