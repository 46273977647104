import Rails from '@rails/ujs';

export const htmlLang = document.documentElement.lang;

export function gotoUrl(url, options) {
	Turbolinks.visit(url, options);
}

export function pageReload() {
	gotoUrl(location.href, {action: 'replace'});
}

export function skipPageCache() {
	const meta = document.createElement('meta');
	meta.name = 'turbolinks-cache-control';
	meta.content = 'no-cache';
	document.getElementsByTagName('head')[0].appendChild(meta);
}

export function addEventListenerToCollection(collection = [], eventName, callback) {
	collection.forEach(element => {
		element.addEventListener(eventName, callback);
	});
}

export function submitByUjs(target) {
	Rails.fire(target, 'submit')
}

export function spawnNew(klass, query = '') {
	document.querySelectorAll(query).forEach(element => {
		new klass(element);
	});
}

export function grep(items, callback) {
	let filtered = [];
	let len = items.length;
	let i = 0;

	for (i; i < len; i++) {
		let item = items[i];
		let cond = callback(item);
		if (cond) {
			filtered.push(item);
		}
	}

	return filtered;
}

export function unique(array = []) {
	grep(array, (el, index) => index === array.indexOf(el));
}
